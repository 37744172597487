import axios from 'axios';
import { Inertia } from '@inertiajs/inertia';

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.code === 'ERR_NETWORK') {
      console.warn('Network error: check your internet connection');
      return Promise.reject(new Error('Network error. Try again later'));
    }

    if (error.response.status === 401) {
      Inertia.visit(route('login'));
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
